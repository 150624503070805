import React from "react";
import { Card, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import UserService from "../../../../services/users.service";
import { UserTable } from "./usertable";


export function DashboardTable() {
  const newPage = localStorage.getItem("showPage");
  const searching = localStorage.getItem("search")
  const story = localStorage.getItem("storyCount")
  const addFilter = localStorage.getItem("addFilterRecord")
  const dateDefault = localStorage.getItem("dateFormat")
  const addFilter2 = localStorage.getItem("addFilterRecord2")
  const storyCountmaxi = localStorage.getItem("storyCountmax")
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(newPage !== null ? newPage : 1);
  const [totalRows, setTotalRows] = useState("");
  const [search, setSearch] = useState(searching !== "" && searching !== null ? searching : "");
  const [addFilterRecord, setAddFilterRecord] = useState(addFilter !== "" && addFilter !== null ? addFilter : "");
  const [addFilterRecord2, setAddFilterRecord2] = useState(addFilter2 !== "" && addFilter2 !== null ? addFilter2 : "");
  const [dateFormat, setDateFormat] = useState(dateDefault !== "" && dateDefault !== null ? dateDefault : "");
  const [storyCount, setStoryCount] = useState(story !== "" && story !== null ? story : "");
  const [storyCountmax, setStoryCountmax] = useState(storyCountmaxi !== "" && storyCountmaxi !== null ? storyCountmaxi : "")

  console.log('addFilter2', addFilterRecord, addFilterRecord2)
  const getAllUserData = async () => {
    const newPageNumber = localStorage.getItem("showPage")
    if (newPageNumber === null) {
      localStorage.setItem("showPage", 1)
    }
    const response = await UserService.getAllUser(
      search.toLowerCase() || "",
      pageNumber,
      dataPerPage,
      addFilterRecord2 === "true" || addFilterRecord2 === "false" ? addFilterRecord2 : "",
      addFilterRecord === "true" || addFilterRecord === "false" ? addFilterRecord : "",
      dateFormat || "",
      storyCount || "",
      storyCountmax || ""
    );
    setTotalRows(response.data.apiresponse.data.count);
    setData(response.data.apiresponse.data.data);
  };

  useEffect(() => {
    getAllUserData();
    localStorage.setItem("showPage", pageNumber)
    localStorage.setItem("search", search)
    localStorage.setItem("dateFormat", dateFormat)
    localStorage.setItem("storyCount", storyCount)
    localStorage.setItem("addFilterRecord", addFilterRecord)
    localStorage.setItem("addFilterRecord2", addFilterRecord2)
    localStorage.setItem("storyCountmax", storyCountmax)
  }, [search, pageNumber, dataPerPage, addFilterRecord2, addFilterRecord, dateFormat, storyCount, storyCountmax]);

  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await getAllUserData();
    setIsLoading(false);
  };

  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    await getAllUserData();
    setIsLoading(false);
  };

  const handleData = (e) => {
    setSearch(e.target.value);
  };

  console.log('dateFormat', pageNumber, search, dateFormat, storyCount, addFilterRecord, addFilterRecord2, storyCountmax)

  return (
    <>
      <div className="page-header2"></div>
      <div className="row">
        <Col sm={12} md={6} lg={6} xl={3}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-order ">
                <label className="main-content-label font-weight-bold mb-3 pt-1">
                  Total Users
                </label>
                <h2 className="text-end card-item-icon card-icon">
                  <i className="fa fa-user icon-size float-start text-primary"></i>
                  <span className="font-weight-bold">{totalRows}</span>
                </h2>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
      <UserTable
        data={data}
        totalRows={totalRows}
        dataPerPage={dataPerPage}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        getAllUser={getAllUserData}
        handleData={handleData}
        setAddFilterRecord={setAddFilterRecord}
        setAddFilterRecord2={setAddFilterRecord2}
        addFilterRecord={addFilterRecord}
        addFilterRecord2={addFilterRecord2}
        setDateFormat={setDateFormat}
        dateFormat={dateFormat}
        setStoryCount={setStoryCount}
        storyCount={storyCount}
        pageNumber={pageNumber}
        search={search}
        setStoryCountmax={setStoryCountmax}
        storyCountmax={storyCountmax}
      />
    </>
  );
}
