import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import UserService from "../../../../services/users.service";
import { SingleUserTable11 } from "./singleUserTable";

export function SingleUserTable() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState("");
  const [imageList, setImageList] = useState([])

  const location = useLocation();
  const id = location?.state?.id;
  const name = location?.state?.name;
  const lastname = location?.state?.lastname;
  const email = location?.state?.email;
  const phone = location?.state?.number;
  const device = location?.state?.device;
  const plan = location?.state?.plan !== null ? location?.state?.plan : "";

  const getAllUserData = async (pagesize) => {
    const response = await UserService.GetSingleUserData(
      pageNumber,
      pagesize === undefined ? 10 : pagesize,
      id
    );
    const getImageList = await UserService.GetImageList(id)
    const filteredData = getImageList.data.apiresponse.data?.filter(item => item.imageType === "COVER");

    const reorderedData = [
      ...filteredData,
      ...getImageList.data.apiresponse.data?.filter(item => item.imageType !== "COVER")
    ];
    setTotalRows(response.data.apiresponse.data.count)
    setImageList(reorderedData)
    setData(response.data.apiresponse.data?.data);
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await getAllUserData(newPerPage);
    setIsLoading(false);
  };
  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    await getAllUserData();
    setIsLoading(false);
  };

  const capitalizeFirstLetter = (str) => {
    if (str !== null) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  };

  console.log(imageList, "imageList")

  return (
    <>
      <div className="page-header2"></div>
      <Card className="custom-card">
        <Card.Body>
          <Row>
            <div>
              <div className="border-bottom mb-3">
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <div
                      className="me-auto d-flex justify-content-start"
                      style={{ fontWeight: 600, fontSize: "20px" }}
                    >
                      USER DETAIL
                    </div>
                  </Row>
                </FormGroup>
              </div>
              <div>
                <div className="row">
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark mt-0">
                          {"First Name"} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control value={name} disabled />
                        {/* value={iconData.firstName} */}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark mt-0">
                          {"Last Name"} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control value={lastname} disabled />
                      </Col>
                    </Row>
                  </FormGroup>

                  <>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark mt-0">
                            {"Email"} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={email} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark mt-0">
                            {"Mobile Number"} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={phone} disabled />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark mt-0">
                            {"Device"} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={capitalizeFirstLetter(device)} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark mt-0">
                            {"Plan"} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={plan !== "" && plan !== "" && plan?.includes("Hard Copy Book + FREE PDF") ? "Hard Copy Book + FREE PDF" : plan !== "" && plan?.includes("Hard Copy Book") && plan !== "" && !plan?.includes("+ FREE PDF") ? "Hard Copy Book" : plan !== "" && plan?.includes("PDF Version") ? "PDF Version" : ""} disabled />
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                </div>
              </div>
            </div>
          </Row>
        </Card.Body>
      </Card>

      <div >
        <div style={{ display: 'flex', overflowX: 'auto', width: '100%', marginBottom: '24px', paddingBottom: '24px' }}>

          {imageList && imageList?.map((item, index) => (
            <>
              <Col xl={2} lg={4} md={6} className="m-3">
                <Row className="row-sm p-0">
                  <Card className="custom-card card p-0">
                    <img
                      className="card-img-top w-100 blog-img"
                      src={item.imageUrl}
                      width='150px'
                      height={item.imageType === "COVER" ? "337px" : '200px'}
                      style={{ objectFit: 'cover', overflow: 'hidden' }}
                      alt=""
                    />
                    <Card.Body>
                      <p className="card-text" style={{ textAlign: 'justify', maxHeight: '180px', overflowY: 'scroll' }}>
                        {item.comment}
                      </p>
                    </Card.Body>
                  </Card>
                </Row>
              </Col>
            </>
          ))}
        </div>

      </div>
      <SingleUserTable11
        data={data}
        dataPerPage={dataPerPage}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        totalRows={totalRows}
        getAllUserData={getAllUserData}
      />
    </>
  );
}
