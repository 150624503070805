import { Button, Form, Modal } from "react-bootstrap";

export const ViewStory = (props) => {
  const { data, setModalShow, title, handleOpen, verify } = props;
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        {title}
      </Modal.Header>
      <Modal.Body className="text-center">
        <div style={{ textAlign: "justify", marginTop: '10px' }}>{data}</div>
        <div style={{ marginTop: '15px' }}>
          {verify &&
            <Button className="ms-auto me-3" onClick={() => handleOpen()}>
              Edit
            </Button>}
          <Button className="ms-auto" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
        </div>
        {/* )} */}
      </Modal.Body>
    </Modal>
  );
};

export const EditStory = (props) => {
  const { formik, onHide } = props;
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <form onSubmit={formik.handleSubmit}>
          <h4>Update Message</h4>
          <Form.Label
            style={{
              textAlign: "start",
              color: "#000",
              marginTop: "15px",
            }}
          >
            TITLE
          </Form.Label>
          <Form.Control
            type="text"
            name="chapterTitle"
            placeholder="Name"
            value={formik.values.chapterTitle}
            onChange={formik.handleChange}
          />
          {formik.errors.chapterTitle && formik.touched.chapterTitle ? (
            <p className="text-start error">{formik.errors.chapterTitle}</p>
          ) : null}

          <Form.Label
            style={{
              textAlign: "start",
              color: "#000",
              marginTop: "15px",
            }}
          >
            TEXT
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            placeholder="Name"
            value={formik.values.description}
            onChange={formik.handleChange}
            style={{ minHeight: '300px' }}
          />
          {formik.errors.description && formik.touched.description ? (
            <p className="text-start error">{formik.errors.description}</p>
          ) : null}

          <div className="d-flex ms-auto" style={{ justifyContent: 'end' }}>
            <Button
              style={{
                display: "flex",
                marginRight: "15px",
                marginTop: "15px",
              }}
              onClick={() => onHide()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              style={{
                display: "flex",
                marginRight: "15px",
                marginTop: "15px",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

// export const DeleteStandingModule = (props) => {
//   const { getAllStanding, onHide } = props;

//   // const deleteRace = async () => {
//   //   setLoader(true);
//   //   await StandingService.DeleteAllStanding();
//   //   getAllStanding();
//   //   onHide();
//   //   setLoader(false);
//   // };
//   return (
//     <Modal
//       {...props}
//       size="large"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       backdrop="static"
//     >
//       <Modal.Header closeButton />
//       <Modal.Body className="text-center">
//         <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

//         <h4 className="tx-danger tx-semibold mg-b-20">Delete !</h4>
//         <p className="mg-b-20 mg-x-20 ">
//           are you sure want to delete All Standing Data !!
//         </p>
//         <Button variant="danger">Delete</Button>
//       </Modal.Body>
//     </Modal>
//   );
// };
