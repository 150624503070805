import { get } from "lodash";
import customAxios from "../serverCall";

const signUp = (userSignInRequest) => {
  return customAxios
    .post(`/auth/loginAdmin`, userSignInRequest)
    // .post(`/auth/loginAdmin?email=${email}&password=${password}`)
    .then((response) => {
      const apiData = get(response, "data.apiresponse.data", null);
      console.log(apiData);
      if (apiData) {
        const email = apiData.email;
        const token = apiData.token;
        const id = apiData.id;
        // localStorage.setItem("token", token);
        const userAndToken = {
          email,
          token,
          id,
        };
        localStorage.setItem("userProfile", JSON.stringify(userAndToken));
      }
      return apiData;
    });
};

const AuthenticationService = {
  signUp,
};

export default AuthenticationService;
