import { CircularProgress } from "@mui/material";
import moment from "moment";
import Multiselect from "react-select";
import { useMemo } from "react";
import { useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import UserService from "../../../../services/users.service";
import Searchable from "react-searchable-dropdown";
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { useMediaQuery } from "../../../../assets/mediaQueryHook";
import { Select } from "antd";


export const UserTable = (props) => {
  const {
    data,
    totalRows,
    dataPerPage,
    handlePageChange,
    handlePerPageChange,
    isLoading,
    getAllUser,
    handleData,
    setAddFilterRecord,
    setAddFilterRecord2,
    addFilterRecord,
    addFilterRecord2,
    setDateFormat,
    storyCount,
    setStoryCount,
    pageNumber,
    search,
    dateFormat,
    storyCountmax,
    setStoryCountmax
  } = props;

  console.log('addFilterRecord', addFilterRecord, addFilterRecord2)

  const navigate = useNavigate();
  const isTablet = useMediaQuery("(max-width: 1325px)");
  const isTabletRes = useMediaQuery("(max-width: 780px)");

  const handleSelect = (ranges) => {
    // setDateRange([ranges.selection]);
    const dateInMilliseconds = new Date(ranges.target.value).getTime();
    setDateFormat(ranges.target.value !== "" ? dateInMilliseconds : "")

  };

  const handleRowClick = (row) => {
    console.log("handleRow", pageNumber);
    localStorage.setItem("showPage", pageNumber)
    localStorage.setItem("search", search)
    localStorage.setItem("dateFormat", dateFormat)
    localStorage.setItem("storyCount", storyCount)
    localStorage.setItem("addFilterRecord", addFilterRecord)
    localStorage.setItem("addFilterRecord2", addFilterRecord2)
    localStorage.setItem("storyCountmax", storyCountmax)
    navigate(`/listofuser/${row.id}`, {
      state: {
        id: row.id,
        name: row.name,
        lastname: row.surname,
        email: row.email,
        number: row.phone,
        device: row.deviceModel,
        plan: row.planTitle,
      },
    });
  };

  const handleChangeRecord = async (id, status) => {
    await UserService.UpdateRecord(id, !status);
    getAllUser();
  };

  const columns = [
    {
      name: <b>FIRST NAME</b>,
      selector: (row) => row.name,
    },
    {
      name: <b>FAMILY NAME</b>,
      selector: (row) => row.surname,
    },
    {
      name: <b>EMAIL ADDRESS</b>,
      selector: (row) => row.email,
      width: "300px",
    },
    {
      name: <b>PAYING CUSTOMER ? [Y/N]</b>,
      selector: (row) => (row.purchased === true ? "Yes" : "No"),
      width: "100px",
    },
    {
      name: <strong className="tx-uppercase">ABLE TO RECORD ? [Y/N]</strong>,
      selector: "totalCommision",
      cell: (row) => (
        <div className="d-flex">
          <>
            {row.ableToRecord === true && (
              <div className="select-yes">
                <Select
                  value={"Yes"}
                  onChange={() => handleChangeRecord(row.id, row.ableToRecord)}
                  style={{ width: "100px" }}
                >
                  <option
                    value="No"
                    className="text-white"
                    style={{ backgroundColor: "#15A552" }}
                  >
                  </option>
                </Select>
              </div>
            )}
            {row.ableToRecord === false && (
              <div className="select-no">
                <Select
                  value={"No"}
                  onChange={() => handleChangeRecord(row.id, row.ableToRecord)}
                  style={{ width: "100px" }}
                >
                  <option
                    value="Yes"
                    className="text-white"
                    style={{ backgroundColor: "#15A552" }}
                  >
                  </option>
                </Select>
              </div>
            )}
          </>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <b>NUMBER OF STORY RECORDED</b>,
      selector: (row) => <div title={row.nationality}>{row.storyCount}</div>,
      width: "100px",
    },
    {
      name: <b>DATE</b>,
      selector: (row) => (
        <div>{moment(row.creationDate).format("DD-MM-YYYY HH:mm")}</div>
      ),
    },
  ];

  const extensions = useMemo(
    () => ({
      filter: true,
    }),
    []
  );

  const options = [
    { value: "", label: "Reset" },
    { value: "true", label: "Yes" },
    { value: "false", label: "No" }
  ]
  const options2 = [
    { value: "", label: "Reset" },
    { value: "true", label: "Yes" },
    { value: "false", label: "No" }
  ]

  const handleTypeChange = (name) => {
    setAddFilterRecord(name.value === "" ? "" : name.value)
  }
  const handleTypeChange2 = (name) => {
    setAddFilterRecord2(name.value === "" ? "" : name.value)
  }

  const handleStoryCountChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    setStoryCount(inputValue);
  };
  const handleStoryCountChangemax = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    setStoryCountmax(inputValue);
  };


  return (
    <>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div className="d-flex">
                  </div>

                  <div className="d-flex col2-12">
                    {!isTablet && (<>
                      <div className="col2-lg-6 col-md-4 align-items-end" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <div
                          style={{
                            justifyContent: "start",
                            marginRight: "2px",
                            marginTop: '15px'
                          }}
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                            }}
                          >
                            Search
                          </Form.Label>
                          <input
                            type="search"
                            style={{
                              border: "1px solid #e8e8f7",
                              padding: "0.6rem 0.75rem",
                              borderRadius: "7px",
                              width: '100%'
                            }}
                            value={search}
                            placeholder="Filter Table"
                            onChange={(e) => handleData(e)}
                          // className="searchtextres"
                          />
                        </div>
                      </div>
                      <div className="col2-lg-6 col-md-8">
                        <div className="col2-12 d-flex align-items-end">
                          <div className="col2-lg-2">
                            <div style={{ marginLeft: '15px', marginBottom: '10px' }}>
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Paying Customer
                              </Form.Label>
                              <div>
                                <Multiselect
                                  classNamePrefix="Select2 z-index-10"
                                  options={options}
                                  placeholder={addFilterRecord === "true" ? "Yes" : addFilterRecord === "false" ? "No" : "Paying Customer"}
                                  onChange={(e) => handleTypeChange(e)}
                                  isSearchable
                                  isMulti={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col2-lg-2">
                            <div style={{ marginLeft: '15px', marginBottom: '10px' }}>
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Able To Record ?
                              </Form.Label>
                              <div>
                                <Multiselect
                                  classNamePrefix="Select2 z-index-10"
                                  options={options2}
                                  placeholder={addFilterRecord2 === "true" ? "Yes" : addFilterRecord2 === "false" ? "No" : "Able To Record"}
                                  onChange={(e) => handleTypeChange2(e)}
                                  isSearchable
                                  isMulti={false}
                                />
                              </div></div>
                          </div>
                          <div className="col2-lg-4">
                            <div style={{
                              marginLeft: '15px',
                              display: 'flex'
                            }}>
                              <div>
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                  }}
                                >
                                  Minimum
                                </Form.Label>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    marginRight: "2px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <input
                                    type="number"
                                    style={{
                                      border: "1px solid #e8e8f7",
                                      padding: "0.6rem 0.75rem",
                                      borderRadius: "7px",
                                      maxWidth: '140px'
                                    }}
                                    value={storyCount}
                                    onChange={handleStoryCountChange}
                                    placeholder="Start count"
                                  // onChange={(e) => handleData(e)}
                                  />
                                </div>
                              </div>
                              <div>
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                  }}
                                >
                                  Maximum
                                </Form.Label>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    marginRight: "2px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <input
                                    type="number"
                                    style={{
                                      border: "1px solid #e8e8f7",
                                      padding: "0.6rem 0.75rem",
                                      borderRadius: "7px",
                                      maxWidth: '140px'
                                    }}
                                    value={storyCountmax}
                                    onChange={handleStoryCountChangemax}
                                    placeholder="End count"
                                  // onChange={(e) => handleData(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col2-lg-4">
                            <div style={{
                              marginBottom: '10px'
                            }}>
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Select Date
                              </Form.Label>

                              <Form.Control
                                type="date"
                                value={dateFormat !== "" && dateFormat !== null && moment(Number(dateFormat)).format("YYYY-MM-DD")}
                                onChange={(e) => handleSelect(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)}

                    {isTablet && (<>
                      <div>
                        <div className={` ${!isTabletRes && 'd-flex'}`}>

                          <div>
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                              }}
                            >
                              Search :
                            </Form.Label>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                marginRight: "2px",
                                marginBottom: "10px",
                              }}
                            >
                              <input
                                type="search"
                                style={{
                                  border: "1px solid #e8e8f7",
                                  padding: "0.6rem 0.75rem",
                                  borderRadius: "7px",
                                  width: '100%'
                                }}
                                value={search}
                                placeholder="Filter Table"
                                onChange={(e) => handleData(e)}
                              />
                            </div>
                          </div>
                          <div className={` ${!isTabletRes && 'ms-4'}`}>
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                              }}
                            >
                              Paying Customer:
                            </Form.Label>
                            <div style={{ width: "max-content" }}>
                              <Multiselect
                                classNamePrefix="Select2 z-index-10"
                                options={options}
                                placeholder={addFilterRecord === "true" ? "Yes" : addFilterRecord === "false" ? "No" : "Paying Customer"}
                                onChange={(e) => handleTypeChange(e)}
                                isSearchable
                                isMulti={false}
                              />
                            </div>
                          </div>
                          <div className={` ${!isTabletRes && 'ms-4'}`} style={{ marginBottom: '15px' }}>
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                              }}
                            >
                              Able To Record ? :
                            </Form.Label>
                            <div style={{ width: "max-content" }}>
                              <Multiselect
                                classNamePrefix="Select2 z-index-10"
                                options={options2}
                                placeholder={addFilterRecord2 === "true" ? "Yes" : addFilterRecord2 === "false" ? "No" : "Able To Record"}
                                onChange={(e) => handleTypeChange2(e)}
                                isSearchable
                                isMulti={false}
                              />
                            </div>
                          </div>

                        </div>
                        <div className={` ${!isTabletRes && 'd-flex'}`}>
                          <div>
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                              }}
                            >
                              Story Count :
                            </Form.Label>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                marginRight: "2px",
                                marginBottom: "10px",
                                width: "max-content"
                              }}
                            >
                              <input
                                type="number"
                                style={{
                                  border: "1px solid #e8e8f7",
                                  padding: "0.6rem 0.75rem",
                                  borderRadius: "7px",
                                }}
                                value={storyCount}
                                onChange={handleStoryCountChange}
                                placeholder="Start count"
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                marginRight: "2px",
                                marginBottom: "10px",
                                width: "max-content"
                              }}
                            >
                              <input
                                type="number"
                                style={{
                                  border: "1px solid #e8e8f7",
                                  padding: "0.6rem 0.75rem",
                                  borderRadius: "7px",
                                }}
                                value={storyCountmax}
                                onChange={handleStoryCountChangemax}
                                placeholder="End count"
                              />
                            </div>
                          </div>
                          <div className={`pos-relative ${!isTabletRes ? 'ms-4' : 'mb-4'}`} >
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                              }}
                            >
                              Select Date :
                            </Form.Label>

                            <Form.Control
                              type="date"
                              value={dateFormat !== "" && dateFormat !== null && moment(Number(dateFormat)).format("YYYY-MM-DD")}
                              onChange={(e) => handleSelect(e)}
                              
                            />
                          </div>
                        </div>
                      </div>
                    </>)}
                  </div>
                  <DataTable
                    {...extensions}
                    data={data}
                    columns={columns}
                    onRowClicked={(row) => handleRowClick(row)}
                    noHeader
                    persistTableHead
                    pagination
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    highlightOnHover
                    center={true}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                    paginationPerPage={dataPerPage}
                    paginationDefaultPage={pageNumber}
                    onChangeRowsPerPage={(page) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    cu
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
