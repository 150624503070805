import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "../app/pages/components/Authentication/Login";
import { lazy } from "react";
import MatxLayout from "../app/pages/components/maxLayout";
import NotFound from "../layouts/sessions/NotFound";
import Loader from "../layouts/Loader/Loader";
import { DashboardTable } from "../app/pages/components/ListOfUser/ListOfUser";
import { SingleUserTable } from "../app/pages/components/singleUser/singleUser";

const Loadable = (Component) => (props) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Component {...props} />
    </React.Suspense>
  );
};

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/listofuser/" />,
      },
      {
        path: "/listofuser/",
        element: <DashboardTable />,
      },
      
      {
        path: "/listofuser/:id",
        element: <SingleUserTable />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <RedirectionWrapper to="/listofuser/" />
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
