export const MENUITEMS = [
  {
    Items: [
      {
        title: "Users",
        path: `/listofuser`,
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
      },
    ],
  },
];
