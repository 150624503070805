import serverCall from "../serverCall";

const getAllUser = async (search, page, limit, ableTorecord, ableTorecord2, date, storyCount, storyMax) => {
  const response = await serverCall.get(
    `/user/admin/get-all-user?creationDate=${date}&ableTorecord=${ableTorecord}&pageNumber=${page}&pageSize=${limit}&searchText=${search}&payingCustomer=${ableTorecord2}&maxStoryCount=${storyMax}&minStoryCount=${storyCount}`
  );
  return response;
};
const getAllUserList = async () => {
  const response = await serverCall.get(`user/find-all-detail/admin`);
  return response;
};

const GetSingleUserData = async (page, pagesize, id) => {
  const response = await serverCall.get(
    `/userstory/admin/getByUser?pageNumber=${page}&pageSize=${pagesize}&userId=${id}`
  );
  return response;
};
const UserDelete = async (id) => {
  const response = await serverCall.delete(
    `/user/delete-account/admin?userId=${id}`
  );
  return response;
};
const UserUpdate = async (data) => {
  const response = await serverCall.put(`/user/update-user-detail/admin`, data);
  return response;
};
const GetUser = async (id) => {
  const response = await serverCall.get(
    `/user/find-one-user/admin?userId=${id}`
  );
  return response;
};
const UpdateRecord = async (id, status) => {
  const response = await serverCall.post(
    `/user/admin/updateAbleRecord?flag=${status}&userId=${id}`
  );
  return response;
};
const GetImageList = async (id) => {
  const response = await serverCall.get(
    `/coverimage/admin/getUserImage?userId=${id}`
  );
  return response;
};
const UpdateStory = async (data) => {
  const response = await serverCall.post(
    `/userstory/admin/update`, data
  );
  return response;
};

const UserService = {
  getAllUser,
  GetSingleUserData,
  UserDelete,
  GetUser,
  UserUpdate,
  getAllUserList,
  UpdateRecord,
  GetImageList,
  UpdateStory
};

export default UserService;
