import { useMemo } from "react";
import { Card } from "react-bootstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { EditStory, ViewStory } from "./viewStorymodel";
import moment from "moment";
import { useFormik } from "formik";
import UserService from "../../../../services/users.service";

export const SingleUserTable11 = (props) => {
  const {
    data,
    dataPerPage,
    handlePerPageChange,
    handlePageChange,
    isLoading,
    totalRows,
    getAllUserData
  } = props;

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const handleClose = () => setModalShow(false);
  const handleClose2 = () => setModalShow2(false);
  const [verify, setVerify] = useState("")

  function truncateText(text, maxLength) {
    if (text && text.length <= maxLength) {
      return text;
    } else {
      return text && text.substr(0, maxLength) + "...";
    }
  }

  const initialValue = {
    id: "",
    chapterTitle: "",
    description: ""
  };

  const submit = async (values) => {
    console.log("values", values)
    try {
      await UserService.UpdateStory(values)
      handleClose2();
      getAllUserData()
    } catch (error) {
      console.error("error", error);
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: submit,
  });

  const handleOpen = () => {
    setModalShow2(true);
    handleClose()
  };
  const handleOpenView = (row) => {
    setModalShow(true);
    formik.setFieldValue("id", row.id)
    formik.setFieldValue("chapterTitle", row.chapterTitle)
    formik.setFieldValue("description", row.description)
    setDescription(row.description);
    setTitle(row.chapterTitle);
    setVerify(row.verified)
  }

  const columns = [
    {
      name: <b >TITLE</b>,
      selector: (row) => <div title={row.chapterTitle}>{row.chapterTitle}</div>,
      sortable: false,
      width: "200px",
    },
    {
      name: <b>TEXT</b>,
      selector: (row) => (
        <div style={{ display: "flex" }}>
          <div title={row.description}>
            {truncateText(row.description, 150)}
          </div>
        </div>
      ),
      sortable: false,
      width: "350px",
    },
    {
      name: <b>SUCCESS/FAIL?</b>,
      selector: (row) => (
        <div title={row.verified === true ? "Yes" : "No"}>
          {row.verified === true ? "Yes" : "No"}
        </div>
      ),
      sortable: false,
      width: "130px",
    },
    {
      name: <b>DATE & TIME</b>,
      selector: (row) => (
        <div>
          {moment(row.creationdate).format("DD-MM-YYYY HH:mm")}
        </div>
      ),
      sortable: false,
      width: "200px",
    },
    {
      name: <b>IS STORY SHARED ?</b>,
      selector: (row) => (
        <div title={row.shareDate === 0 ? "No" : "Yes"}>
          {row.shareDate === 0 ? "No" : "Yes"}
        </div>
      ),
      width: "100px",
      sortable: false,
    },
    {
      name: <b>SHARED STORY DATE</b>,
      selector: (row) => (
        <div>
          {row.shareDate !== 0 && moment(row.shareDate !== 0 && row.shareDate).format("DD-MM-YYYY HH:mm")}
        </div>
      ),
      sortable: false,
      width: "200px",
    },

  ];

  const tableData = {
    columns,
    data,
  };

  const extensions = useMemo(
    () => ({
      filter: true,
    }),
    []
  );


  return (
    <>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <h6
                    className="main-content-label mb-1 fs-20"
                    style={{ marginLeft: "5px" }}
                  >
                    CHAPTERS
                  </h6>
                </div>
                <div>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      {...extensions}
                      data={data}
                      columns={columns}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      center={true}
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationRowsPerPageOptions={[5, 10, 50, 100]}
                      paginationPerPage={dataPerPage}
                      onChangeRowsPerPage={(page) => {
                        handlePerPageChange(page, 1);
                      }}
                      onChangePage={handlePageChange}
                      progressPending={isLoading}
                      onRowClicked={(row) => handleOpenView(row)}
                      progressComponent={
                        <CircularProgress
                          size={30}
                          style={{ marginTop: "50px" }}
                        />
                      }
                    />
                  </DataTableExtensions>
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
      {modalShow && (
        <ViewStory
          show={modalShow}
          title={title}
          onHide={handleClose}
          data={description}
          setModalShow={setModalShow}
          handleOpen={handleOpen}
          verify={verify}
        />
      )}

      {modalShow2 && (
        <EditStory
          show={modalShow2}
          onHide={handleClose2}
          setModalShow={setModalShow2}
          formik={formik}
        />
      )}
    </>
  );
};
